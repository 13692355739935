





















































































































.dashboard-card {
    min-height: 350px;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
}
